<template>
  <div id="ag-grid-demo">
    <vx-card>
      <div class="flex flex-wrap justify-end items-center mb-6 gap-3 actions-container">
        <slot name="action" />

        <div v-can="permissions.add" v-if="checkAgGridEvent('emitAddNewRecord')">
          <button v-if="checkAgGridEvent('emitAddNewRecord')" @click="addNewRecord()"
            class="flex items-center btn_Option px-3 ml-4 add">
            <feather-icon icon="PlusIcon" class="mr-2 button_icon" />
            <span>إضافة</span>
          </button>
        </div>

        <div v-can="permissions.delete" v-if="checkAgGridEvent('emitDeleteMultipleRecords')">
          <button v-if="checkAgGridEvent('emitDeleteMultipleRecords')" @click="confirmDeleteMultipleRecords()"
            class="flex items-center btn_Option px-3 ml-4 del">
            <feather-icon icon="Trash2Icon" class="mr-2 button_icon" />
            <span>حذف متعدد</span>
          </button>
        </div>
        <button v-if="checkAgGridEvent('emitExportToExcel')" @click="exportToExcel()"
          class="flex items-center btn_Option px-3 ml-4 export">
          <feather-icon icon="DownloadIcon" class="mr-2 button_icon" />
          <span>تصدير اكسل</span>
        </button>
        <div v-can="permissions.import">
        <import-excel  v-if="checkAgGridEvent('emitImportFromExcel')"
                       :is-button="true"
                       :on-success="importFromExcel"/>
        </div>
        <button v-if="checkAgGridEvent('emitShowDetails')" @click="showDetails()"
          class="flex items-center btn_Option ml-4 px-3 showDetails">
          <feather-icon icon="ListIcon" class="mr-2 button_icon" />
          <span>عرض التفاصيل</span>
        </button>

        <button v-if="showCompleted && checkAgGridEvent('emitShowCompletedRecords')" @click="showCompletedRecords()"
          class="flex items-center btn_Option ml-4 px-3 showCompleted">
          <feather-icon icon="KeyIcon" class="mr-2 button_icon" />
          <span>عرض الطلبات المنتهية</span>
        </button>

        <button v-if="!showCompleted" @click="showCurrentRecords()"
          class="flex items-center btn_Option ml-4 px-2 showCurrent">
          <feather-icon icon="ActivityIcon" class="mr-2 button_icon" />
          <span>عرض الطلبات الفعالة</span>
        </button>

        <button v-if="showCanceled && checkAgGridEvent('emitShowCanceledRecords')" @click="showCanceledRecords()"
          class="flex items-center btn_Option ml-4 px-2 showCurrent">
          <feather-icon icon="XIcon" class="mr-2 button_icon" />
          <span>عرض الحجوزات الملغية</span>
        </button>

        <button v-if="!showCanceled" @click="showCurrentRecords()"
          class="flex items-center btn_Option ml-4 px-2 showCurrent">
          <feather-icon icon="ActivityIcon" class="mr-2 button_icon" />
          <span>عرض كافة الحجوزات</span>
        </button>
      </div>

      <!-- class="ag-theme-material ag-grid-table"
        style="width: 100%;" -->

      <ag-grid-vue :animate-rows="true" @first-data-rendered="onFirstDataRendered" :cache-block-size="cacheBlockSize"
        :default-col-def="defaultColDef" :enable-rtl="rtl" :floating-filter="true" :grid-options="gridOptions"
        :pagination="true" :pagination-page-size="paginationPageSize" :row-drag-managed="false"
        :row-model-type="rowModelType" :server-side-store-type="serverSideStoreType"
        :suppress-column-virtualisation="true" :suppress-pagination-panel="true" @grid-ready="onGridReady" id="myGrid"
        ref="agGridTable" row-selection="multiple" style="height: 612px;" class="ag-theme-custom-vue" />


      <div class="flex justify-between items-center mt-6">
        <div class="mr-4 ag-grid-table-actions-left flex items-center">
          <p class="mr-3 w-48">
            عرض المعلومات بالجدول
          </p>
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-2 w-32 border border-solid rounded-lg cursor-pointer flex items-center justify-between">
              <!-- <span class="mr-4 w-24" style="direction: ltr;">{{currentPage * paginationPageSize - (paginationPageSize - 1)}}  -  {{rowData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : rowData.length}}  of  {{ rowData.length }}</span> -->
              <span class="mr-3 ">{{
                currentPage * paginationPageSize - (paginationPageSize - 1)
              }} - {{
  totalRows - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : totalRows
}}</span>
              <feather-icon icon="ChevronDownIcon" svg-classes="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="getPerPage(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="getPerPage(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="getPerPage(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="getPerPage(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="getPerPage(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <span class="ml-4 w-24"> من {{ totalRows }}</span>
        </div>


        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </div>
    </vx-card>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import utilities from "@/app/shared/utilities";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import 'ag-grid-enterprise';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import ImportExcel from "@/components/excel/ImportExcel";

export default {
  data() {
    return {
      setPaginationPageSize: false,
      maxPageNumbers: 7,
      showCompleted: true,
      page: '',
      query: '',
      perPage: '',
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        minWidth: 120,
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
        filterParams: {
          buttons: ['apply', 'reset']
        }
      },
      totalRows: '',
      dataSource: '',
      rowModelType: null,
      serverSideStoreType: null,
      cacheBlockSize: null,
      data: [],
      showCanceled: true,
      filterKeys: {},
      filterValues: {}
    };
  },
  props:
  {
    gridOptions: {
      type: Object,
      default: null
    },
    dataSourceApi: {
      type: Function,
      default: null
    },
    permissions: {
      type: Object,
      default: null
    },
    rtl: {
      type: Boolean,
      default: true
    },
    isEntitiesResponse: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ImportExcel,
    'ag-grid-vue': AgGridVue,
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned('email', null);
      } else this.gridOptions.columnApi.setColumnPinned('email', 'left');
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created() {
    if (!this.rtl) {
      this.gridOptions.columnDefs.reverse();
    }
    this.rowModelType = 'serverSide';
    this.serverSideStoreType = 'partial';
    this.cacheBlockSize = this.paginationPageSize;
  },
  methods: {

    onFirstDataRendered() {
      this.gridApi.paginationSetPageSize(Number(10));
    },

    getPerPage(perPage) {
      this.gridApi.paginationSetPageSize(perPage);
      this.perPage = perPage;
      this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', perPage);
      this.gridApi.setServerSideDatasource(this.datasource);
    },
    addNewRecord() {
      this.$emit('emitAddNewRecord');
    },
    exportToExcel() {
      this.$emit('emitExportToExcel');
    },
    importFromExcel(excelData) {
      this.$emit('emitImportFromExcel', excelData);
    },
    showCanceledRecords() {
      this.showCanceled = false;
      this.$emit('emitShowCanceledRecords');
      this.gridApi.refreshServerSideStore({ purge: true });
    },
    showCurrentRecords() {
      this.showCompleted = true;
      this.showCanceled = true;
      this.$emit('emitShowCurrentRecords');
      this.gridApi.refreshServerSideStore({ purge: true });
    },
    showCompletedRecords() {
      this.showCompleted = false;
      this.$emit('emitShowCompletedRecords');
    },
    showDetails() {
      this.$emit('emitShowDetails');
    },
    deleteMultipleRecords(recordsIds) {
      this.$emit('emitDeleteMultipleRecords', recordsIds);
    },
    confirmDeleteMultipleRecords() {
      if (!this.gridOptions.api.getSelectedRows().length)
        return;
      let recordsIds = this.gridOptions.api.getSelectedRows().map(element => element.id);
      let title = ' هل أنت متأكد من حذف ' + recordsIds.length + '  من العناصر ؟ ';
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: title,
        text: `في حال الموافقة, لن تتمكن من التراجع!`,
        accept: this.deleteMultipleRecords,
        acceptText: 'حذف',
        parameters: [recordsIds]
      });
    },
    checkAgGridEvent(event) {
      return this.$listeners && this.$listeners[event];
    },
    onGridReady(params) {
      const that = this;
      that.datasource = {
        getRows: function (params) {
          if (that.isEntitiesResponse)
            that.query = utilities.getQueryParameters(params, that.paginationPageSize, that.currentPage);
          else
            that.query = utilities.getQueryParameters(params, that.paginationPageSize, that.currentPage, true);
          if (!that.showCanceled)
            that.query = '/trashed' + utilities.getQueryParameters(params, that.paginationPageSize, that.currentPage);
          that.dataSourceApi(that.query).then((response) => {
            if (that.isEntitiesResponse) {
              that.totalRows = response.total;
              params.successCallback(response.entities, response.total);
            } else {
              that.totalRows = response.total;
              params.successCallback(response.data, response.total);
            }
          });
        }
      };
      params.api.setServerSideDatasource(that.datasource);

    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    let allColumnIds = [];
    let columnsWidth = 0;
    this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.getColId());
      columnsWidth += column.getActualWidth();
    });

    if (this.$store.state.windowWidth >= columnsWidth)
      this.gridOptions.api.sizeColumnsToFit();

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  }
};
</script>


<style lang="scss">
@import "ag-grid-community/dist/styles/ag-grid.scss";
@import "ag-grid-community/dist/styles/ag-theme-material/sass/_ag-theme-material-mixin.scss";
@import "./myStyle.scss";
</style>
